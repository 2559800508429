export const marketplaceAddress = "0x660C56a3f42D9527324af8A51CBe78E8f2Db17aE";
export const auctionAddress = "0xFA2238eEb4E2E5612853BF29f925a36f971F9010";

export const manifoldOpenEditionAddress =
  "0x26BBEA7803DcAc346D5F5f135b57Cf2c752A02bE";

export const contractAddress = "0x5E2e33cd6E91b48928B2Cfd6F28a7BBd58468a5C";

export const artistId = 2603;
export const artistAddress = "0x9Dbef4969a9B01994A05867b2c25c169C60c7048";

export const projectName = "Pepehaus";
export const projectLink = "https://pepehaus.ninfa.io";

export const etherscanUrl = "https://etherscan.io";
export const basescanUrl = "https://basescan.org";

export const startingDate = 1720458000000;

export const artistName = "Movsum";
export const bio = [
  `Movsum is an artist whose life orbits around lines, movement, and geometric forms. Drawing early inspiration from his natural surroundings and the captivating geometry of Wassily Kandinsky, their artistry seamlessly brings to life a hidden realm of movement and geometry through dynamic visuals in the digital world.`,
  `Deep dives into tools like Adobe Illustrator and Procreate, guided by inspirations like J Dilla's musical genius, sneaker culture, and architecture, have forged Movsum’s artistic expression into a unique, harmonious fusion of seemingly contradictory colors and shapes. Now further venturing into the emerging fine art realm of Web3, every piece they create is not just art but a chapter of their story, a heartbeat, and an echoing legacy. Watch this space as Movsum unveils the "contradiction of balance" through their distinctive lens.`,
];

export const ninfaUrl = "https://ninfa.io";

export const artistUrl = "https://ninfa.io/@movsum";
export const artistTwitter = "https://x.com/w_movsum";
export const artistInsta = "https://www.instagram.com/w.movsum/";

export const interviewPath = "/interview/A-conversation-with-Movsum-10";
export const articlePath =
  "/article/Movsum-and-the-Bauhaus-Influence:-A-Digital-Art-Tribute-11";
export const standard = "ERC-721";
export const mintedOn = "Manifold";
export const totalPieces = "21";

export const validChainId = 1;

export const openEdition = {
  title: "Placeholder",
  description: "",
  price: 0,
  instanceId: 0,
  contractAddress: "0x0B4B102742A68B422B02563CFe226a3ba2bA3420",
  tokenId: 1,
  originalImageUrl: "",
  metadata: "",
  startDate: 1715097600,
  endDate: 1715184000,
} as const;

export const manifoldFee = 0.0005;

export const compressedImageBaseUrl = "https://images.ninfa.io/nfts/original";
